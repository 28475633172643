
import { defineComponent, reactive } from '@vue/composition-api'

import ContractsView from '@/views/contractData/views/contracts/Contracts.vue'

import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'Contracts',
  components: {
    ContractsView,
  },
  props: {
    withoutFilterPersistence: {
      type: Boolean,
      default: false,
    },
  },
  setup: (_, { root }) => {
    const TRANSLATION_KEY = 'contracts'

    const TABLE_HEADERS = [
      {
        text: root.$t('contractData.contracts.table.col.title.id'),
        value: 'id',
        sortable: true,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.organizationalUnit'),
        value: 'organizationalUnit.name',
        sortable: false,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.contractualRelationship'),
        value: 'contractualRelationship',
        sortable: true,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.supplier'),
        value: 'supplier.name',
        sortable: false,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.contractStart'),
        value: 'start',
        sortable: true,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.contractEnd'),
        value: 'end',
        sortable: true,
      },
      hasSufficientRights(Rights.FINANCIAL_READ) && {
        text: root.$t('contractData.contracts.table.col.title.hourlyRate'),
        value: 'hourlyRate',
        sortable: true,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.scope'),
        value: 'scope',
        sortable: true,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.tasksCount'),
        value: 'tasksCount',
        sortable: false,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.notesCount'),
        value: 'notesCount',
        sortable: false,
      },
      {
        text: root.$t('contractData.contracts.table.col.title.filesCount'),
        value: 'filesCount',
        sortable: false,
      },
      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    return reactive({
      constants: {
        TRANSLATION_KEY,

        TABLE_HEADERS,
      },
    })
  },
})
