var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ContractsView', {
    attrs: {
      "component-table-headers": _vm.constants.TABLE_HEADERS,
      "filter-by-id": Number(_vm.$route.params.id),
      "without-filter-persistence": _vm.withoutFilterPersistence,
      "elevation": 0,
      "translation-key": _vm.constants.TRANSLATION_KEY
    },
    on: {
      "reload-person": function reloadPerson($event) {
        return _vm.$emit('reload-person');
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }